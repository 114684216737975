<template>
  <div ref="animatedNumbers" class="animated-numbers">
    <div class="animated-number" v-for="({ number, title}, i) in numbersData" :key="i">
      <span class="number utopia-blk-headline">{{ tweenedValues[i] }}</span>
      <span class="title slate-bold">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import { tween } from 'shifty';

export default {
  name: 'AnimatedNumbers',
  props: {
    numbersData: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data() {
    return {
      tweenedValues: [0,0,0,0,0,0],
      maxDuration: 2000,
      minDuration: 1200,
    };
  },
  computed: {
    numbersDataNumbers() {
      return this.numbersData.slice().map((d) => d.number);
    },
    dataMax() { return Math.max(...this.numbersDataNumbers) },
    dataMin() { return Math.min(...this.numbersDataNumbers) },
  },
  mounted() {
    function intersectionCallback(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.intersectionRatio >= 1) {
            // For each number passed, set the animation from 0 to the value (tweening).
            this.numbersData.forEach(({ number }, i) => {
              // We normalise the duration between 1.2s and 2s, so they end at different times
              const duration = parseInt((this.maxDuration - this.minDuration) * ((number - 5) / (this.dataMax - this.dataMin)) + this.minDuration);
              tween({
                from: { x: 0 },
                to: { x: number },
                duration,
                easing: 'easeInOutCubic',
                step: state => (this.$set(this.tweenedValues, i, Math.round(state.x))), // use this.$set to keep reactivity to use in template
              });
            })
            // Unobserve the ref so it does not animate again
            this.observer.unobserve(this.$refs.animatedNumbers);
          }
        }
      });
    }

    var options = {
      root: null,
      rootMargin: '-20px 0px 0px 0px', // don't start intersection until 20px after numbers are in view
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(intersectionCallback.bind(this), options);
    this.observer.observe(this.$refs.animatedNumbers);
  },
  beforeDestroy() {
    this.observer.unobserve(this.$refs.animatedNumbers);
  },
}
</script>

<style lang="scss" scoped>
.animated-numbers {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  // small
  @media screen and (max-width: $medium - 1) {
    flex-wrap: wrap;
  }

  @media screen and (min-width: $extra-large) {
    margin-top: 62px;
  }
}

.animated-number {
  margin-right: 9px;
  flex: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  // small
  @media screen and (max-width: $medium - 1) {
    flex: 0 1 31%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.number {
  color: $ey-yellow;
  text-align: center;
  border-bottom: 1px solid #fff;

  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 51px;
    line-height: 80px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 48px;
    line-height: 80px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 66px;
    line-height: 116px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 72px;
    line-height: 116px;
  }
}

.title {
  text-align: center;
  margin-top: 14px;
  // small
  @media screen and (max-width: $medium - 1) {
    font-size: 15px;
    line-height: 17px;
  }

  // medium
  @media screen and (min-width: $medium) and (max-width: $large - 1) {
    font-size: 15px;
    line-height: 17px;
  }

  // large
  @media screen and (min-width: $large) and (max-width: $extra-large - 1) {
    font-size: 16px;
    line-height: 20px;
  }

  // extra-large
  @media screen and (min-width: $extra-large) {
    font-size: 18px;
    line-height: 20px;
  }
}
</style>
